import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //src: 'https://localhost:44320',
    //subDirectory: '',
    src: 'https://www.thinker.tw',
    subDirectory: '/Platform',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
