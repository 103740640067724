<template>
  <!-- 頭 -->
  <nav class="navbar navbar-expand-xl" :class="$route.name == 'Home' ? 'home aa' : ''"
    :style="$route.name == 'Home' || $route.name == 'Contact' ? '' : 'margin-top: -100px;'">
    <div class="container">
      <div class="logo_box">
        <router-link class="navbar-brand" to="/">
          <img src="img/logo.svg" alt="">
        </router-link>
      </div>
      <div class="menu_box" v-if="HeadData && HeadData.Header_Menu">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="line1"></span>
          <span class="line2"></span>
          <span class="line3"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggleExternalContent">
          <ul class="navbar-nav menu">
            <li class="nav-item" :class="MenuData.SubMenus ? 'dropdown' : ''"
              v-for="(MenuData, index) in HeadData.Header_Menu" :key="index">
              <template v-if="!MenuData.SubMenus">
                <a class="nav-link" href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
              </template>
              <template v-if="MenuData.SubMenus">
                <a class="nav-link dropdown-toggle" href="#" title="" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ MenuData.Menu.Title }}
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" v-for="(SubMenuData, index_sub) in MenuData.SubMenus" :key="index_sub"
                    href="javascript:void(0)" @click="goto(SubMenuData)">{{ SubMenuData.Title }}</a>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="top_btn">
      <a href="#">
        <p>TOP</p>
      </a>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Head",
  components: {
  },
  data() {
    return {
      HeadData: null,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
        {
          params: {
            lang: $cookies.get("thinker_site_lang")
          },
        }
      )
      .then((response) => {
        this.HeadData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("thinker_site_lang")) {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = $cookies.get("thinker_site_lang");
    } else {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = "zh_tw";
    }
    // 回頂端
    $("html body").scroll(function () {
      var N = $("html body").scrollTop();
      if (N > 400) {
        $('.top_btn').css('display', 'block');
      }
      else {
        $('.top_btn').css('display', 'none');
      };
    });
    $(".top_btn a").click(function () {
      $('html,body').animate({ scrollTop: 0 }, 1000);
    });
  },
  methods: {
    changeLang(lang) {
      $cookies.set("thinker_site_lang", lang, "30d");
      location.reload();
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Title == '服務項目') {
        //console.log("document.documentElement.scrollTop: " + document.documentElement.scrollTop);
        //console.log("document.body.scrollTop: " + document.body.scrollTop);
        if (this.$route.name == 'Home') {
          const targetScrollTop = $('#service-items').offset().top;
          document.documentElement.scrollTop = targetScrollTop; // 主要目標
          document.body.scrollTop = targetScrollTop; // 備用目標
        } else {
          this.$router.push({
            name: "Home",
            query: { to: 'Services' },
          });
        }
      } else {
        if (MenuData.Type == 1) {
          this.gotoCategoryByCId(MenuData.CId);
        } else if (MenuData.Type == 2) {
          this.gotoPostByPId(MenuData.PId);
        } else if (MenuData.Type == 3) {
          this.gotoUrl(MenuData.Url, MenuData.Blank);
        }
      }
    },
  },
};
</script>