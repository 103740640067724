import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import './utils/recaptcha';

Vue.config.productionTip = false

Vue.filter('timeString', function (value, myFormat) {
  return moment(value).format(myFormat || 'YYYY-MM-DD HH:mm:ss');
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
})