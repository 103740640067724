<template>
  <div>
    <Head />

    <div class="wrapper home">
      <!-- banner -->
      <section class="banner">
        <div class="container-fluid">
          <div class="box">
            <div class="white_bg"></div>
            <div class="bg"></div>
            <!-- banner的底圖 -->
            <img src="img/banner_img.png" alt="">
            <div class="text_box">
              <div class="text">
                <p>Thinker，沉思者。</p>
                <h1>最專業的網頁建置團隊</h1>
                <p>我們不斷的思考，如何選擇未來的道路，與客戶共同成就目標。</p>
                <!-- 證照彈跳視窗 按鈕 -->
                <!-- <div class="certificate_p" data-bs-toggle="modal" data-bs-target="#certificate_box">
                  <img src="img/logo-afaq-iso-27001-png.webp" alt="">
                  <p>
                    <span>認證證照：</span>
                    <span>ISO 27001:2022</span>
                  </p>
                </div> -->
              </div>
            </div>
            <div class="contact_box">
              <router-link :to="{ name: 'Contact' }">
                <div class="circle_1">
                  <div class="circle">
                    <p>
                      <span>聯</span>
                      <span>絡</span>
                      <span>我</span>
                      <span>們</span>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <!-- 證照彈跳視窗  -->
      <!-- <div class="modal fade certificate_box" id="certificate_box" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="btn_box">
                <p>資訊安全管理系統（Information Security Management System，ISMS）</p>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="pdf_box">
                <embed src="img/PDF.pdf" type="application/pdf" width="" height="">
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 服務項目 -->
      <section class="services" id="service-items">
        <div class="bg">
          <div class="title_box">
            <h1>服務項目</h1>
          </div>
          <div class="container">
            <ul class="list">
              <li v-for="(MenuData, index) in HomeData.Service_Menu" :key="index">
                <a href="javascript:void(0)" @click="goto(MenuData.Menu)">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" :src="'img/icon_' + (index + 1) + '_w.svg'" alt="">
                      <img class="icon_b" :src="'img/icon_' + (index + 1) + '.svg'" alt="">
                    </div>
                    <div class="text">
                      <h2>{{ MenuData.Menu.Title }}</h2>
                      <p>{{ MenuData.Menu.Description }}</p>
                    </div>
                  </div>
                </a>
              </li>
              <!-- <li>
                <router-link :to="{ name: 'Post', params: { PId: 284, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_1_w.svg" alt="">
                      <img class="icon_b" src="img/icon_1.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>客製化專案服務</h2>
                      <p>是不是覺得很多網站長得大同小異？我們依各行業需求靈活開發，從形象網站到電商網站都適用。疑難雜症就找新客資訊！</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 285, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_2_w.svg" alt="">
                      <img class="icon_b" src="img/icon_2.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>管理系統自主開發</h2>
                      <p>堅持自主開發，免除第三方平台衍生問題，實現安全、快速又可靠的使用環境。另可搭配APP，獲得更完善的操作體驗。</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 286, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_3_w.svg" alt="">
                      <img class="icon_b" src="img/icon_3.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>多元金流串接</h2>
                      <p>不論銀行、國內外第三方金流，只要是想得到的管道都行。創造高營收，搞定金流是首要任務。</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 287, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_4_w.svg" alt="">
                      <img class="icon_b" src="img/icon_4.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>無障礙網站設計</h2>
                      <p>打造人人都能輕鬆瀏覽的網頁！新客資訊擁有豐富建置經驗，簡單實現平等與包容，符合國際與法規標準，兼得SEO紅利！</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 288, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_5_w.svg" alt="">
                      <img class="icon_b" src="img/icon_5.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>雲端主機託管</h2>
                      <p>選用符合Tier III認證等級最高規格的遠傳IDC雲端機房，提供您的網站最專業最穩定的主機託管與維護服務。</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 289, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_6_w.svg" alt="">
                      <img class="icon_b" src="img/icon_6.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>網站資安防護</h2>
                      <p>配合政府資安規範，所有專案上線前進行全面性弱點掃描，並可配合A級公務機關執行頻率，提供中文評估報告。</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 290, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_7_w.svg" alt="">
                      <img class="icon_b" src="img/icon_7.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>SEO排名優化</h2>
                      <p>提供全面性的關鍵字分析及網站優化服務，為您的網站爭取更好的SEO排名，提升自然點擊率，把行銷資源效益最大化，業績UP！</p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 291, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_8_w.svg" alt="">
                      <img class="icon_b" src="img/icon_8.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>活動網頁建置</h2>
                      <p>還在用綁手綁腳的社群粉專嗎？我們有建置長期活動報名網站經驗，不論活動大小、時間長短都能搞定，再其他搭配工具，高流量到手！ </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Post', params: { PId: 292, CId: 48 } }">
                  <div class="text_box">
                    <div class="img_box">
                      <img class="icon_w" src="img/icon_9_w.svg" alt="">
                      <img class="icon_b" src="img/icon_9.svg" alt="">
                    </div>
                    <div class="text">
                      <h2>專業網頁設計</h2>
                      <p>最新RWD網頁設計技術搭配多樣性適合不同產業的網站版型，不受限制的網站設計方式，讓網頁變的更加靈活多變。</p>
                    </div>
                  </div>
                </router-link>
              </li> -->
            </ul>
          </div>
        </div>
      </section>
      <!-- 案例精選 -->
      <section class="case">
        <template v-if="HomeData.Case_Category">
          <div class="title_box">
            <h1>{{ HomeData.Case_Category.CategoryName }}</h1>
          </div>
          <div class="container">
            <!-- 案例內文們 -->
            <div class="box" :class="TabIndex == Post.PId ? 'show add' : ''"
              v-for="Post in HomeData.Case_Category.Posts" :key="Post.PId">
              <div class="img_box">
                <img v-if="Post.Image" :src="HomeData.SiteUrl + Post.Image" alt="">
                <img v-else src="img/img_1.png" alt="">
              </div>
              <div class="text">
                <h2>{{ Post.Title }}</h2>
                <p>{{ Post.SimpleContent }}</p>
                <div class="btn">
                  <button @click="gotoPost(Post)">前往網站</button>
                </div>
              </div>
            </div>
            <!-- 按鈕們 -->
            <ul class="btn_box">
              <li v-for="Post in HomeData.Case_Category.Posts" :key="Post.PId" @click="TabIndex = Post.PId">
                <div class="case_btn" :class="TabIndex == Post.PId ? 'active' : ''">
                  <h2>{{ Post.Title }}</h2>
                  <div class="img_box">
                    <img v-if="Post.Image" :src="HomeData.SiteUrl + Post.Image" alt="">
                    <img v-else src="img/img_1.png" alt="">
                  </div>
                </div>
              </li>
              <div class="more_box">
                <button @click="gotoCategoryByCId(HomeData.Case_Category.Category.CId)">
                  <p>查看更多案例</p>
                </button>
              </div>
            </ul>
          </div>
          <div class="line"></div>
        </template>
      </section>
      <!-- 科技新知 -->
      <section class="news">
        <template v-if="HomeData.News_Category">
          <div class="bg">
            <div class="title_box">
              <div class="container">
                <h1>{{ HomeData.News_Category.Category.CategoryName }}</h1>
              </div>
            </div>
            <div class="container">
              <ul class="list">
                <li v-for="Post in HomeData.News_Category.Posts" :key="Post.PId">
                  <a href="javascript:void(0)" @click="gotoPost(Post)">
                    <div class="text_box">
                      <div class="text">
                        <div class="img_box">
                          <img v-if="Post.Image" :src="HomeData.SiteUrl + Post.Image" alt="">
                          <img v-else src="img/img_1.png" alt="">
                        </div>
                        <p class="time">{{ Post.PublicStartTime | timeString("YYYY/MM/DD") }}</p>
                        <p>{{ Post.Title }}</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="more_box">
            <button @click="gotoCategoryByCId(HomeData.News_Category.Category.CId)">
              <p>查看更多</p>
            </button>
          </div>
        </template>
      </section>
      <!-- 聯絡我們 -->
      <section class="contact">
        <div class="box">
          <!-- 聯絡我們的底圖 -->
          <img src="img/img_contact.png" alt="">
          <div class="container">
            <div class="text_box">
              <div class="text">
                <h3>為您提供最專業的服務</h3>
                <p>留下您的資訊，我們收到後將盡快與您聯繫</p>
              </div>
              <div class="btn_box">
                <router-link :to="{ name: 'Contact' }">
                  <div class="circle_1">
                    <div class="circle">
                      <p>
                        <span>聯</span>
                        <span>絡</span>
                        <span>我</span>
                        <span>們</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Home",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      HomeData: {},
      TabIndex: -1,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: {
            lang: $cookies.get("tmaroc_site_lang")
          },
        }
      )
      .then((response) => {
        this.HomeData = response.data;
        if (this.HomeData.Case_Category && this.HomeData.Case_Category.Posts.length > 0) {
          this.TabIndex = this.HomeData.Case_Category.Posts[0].PId;
        }
        if (this.$route.query.to == 'Services') {
          const targetScrollTop = $('#service-items').offset().top;
          document.documentElement.scrollTop = targetScrollTop; // 主要目標
          document.body.scrollTop = targetScrollTop; // 備用目標
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("thinker_site_lang")) {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = $cookies.get("thinker_site_lang");
    } else {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = "zh_tw";
    }
    let that = this;
    $(function () {
      $("html body").scroll(function () {
        if (that.$route.name == 'Home') {
          var N = document.body.scrollTop;
          // 首頁 視差滾動 
          $('.banner .box .text').css('transform', 'translateX(' + (N * -0.3) + 'px)');
          $('.banner .contact_box').css('transform', 'translateX(' + (N * 0.5) + 'px)' + 'translateY(' + (N * -0.2) + 'px)');
          $('.banner .certificate_box').css('transform', 'translateX(' + (N * 0.3) + 'px)' + 'translateY(' + (N * -0.1) + 'px)');
          $('.banner .scroll_box').css('transform', 'translateX(' + (N * 0.2) + 'px)');
          // 服務項目出現動畫
          var services_top = $('.services').position().top;
          if (N > services_top / 2) {
            $('.services .list li').each(function (index) {
              setTimeout(() => {
                $(this).addClass("addli");
              }, index * 100);
            });
          }
          else {
            $(".services .list li").removeClass("addli");
          };
          // 案例精選 出現動畫
          var case_top = $('.case').position().top - 500;
          if (N > case_top) {
            $('.case .box').addClass('add');
            $('.case .btn_box').addClass('add');
          }
          else {
            $('.case .box').removeClass('add');
            $('.case .btn_box').removeClass('add');
          };
          // 科技新知出現動畫
          var news_top = $('.news').position().top - 500;
          if (N > news_top) {
            $('.news .list li').each(function (index) {
              setTimeout(() => {
                $(this).addClass("add");
              }, index * 300); // 每個 li 延遲 150ms
            });
          }
          else {
            $(".news .list li").removeClass("add");
          };
          // 聯絡我們 出現動畫
          var contact_top = $('.contact').position().top - 500;
          if (N > contact_top) {
            $('.contact .box .container .text_box').addClass('add');
            $('.contact .text h3').addClass('add');
            $('.contact .text p').addClass('add');
          }
          else {
            $('.contact .box .container .text_box').removeClass('add');
            $('.contact .text h3').removeClass('add');
            $('.contact .text p').removeClass('add');
          };
        }
      });
    });
  },
  updated: function () {
  },
  methods: {
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        // this.$router.push({
        //   name: "Post",
        //   params: { PId: Post.PId },
        // });
        const routeData = this.$router.resolve({
          name: "Post",
          params: { PId: Post.PId },
        });
        window.open(routeData.href, '_blank');
      }
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    },
  },
};
</script>
