<template>
  <div>
    <Head />

    <nav aria-label="breadcrumb" v-if="CategoryData">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ CategoryData.Category.CategoryName }}</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_case" v-if="CategoryData.Category.Type == 1">
      <div class="container">
        <ul class="btn_box" v-if="CategoryData.Category.IsShowSub">
          <li v-for="(Tag, index) in CategoryData.Tags" :key="index">
            <button @click="SelectTag = Tag; loadList(1)" :class="SelectTag == Tag ? 'active' : ''"><span>{{ Tag
                }}</span></button>
          </li>
        </ul>
        <ul class="list">
          <li v-for="Post in Posts" :key="Post.PId">
            <a href="javascript:void(0)" class="text" @click="gotoPost(Post)">
              <div class="tag_box" v-if="Post.Tag">
                <div class="tag">{{ Post.Tag }}</div>
              </div>
              <h2>{{ Post.Title }}</h2>
              <div class="img_box">
                <img :src="CategoryData.SiteUrl + Post.Image" alt="" v-if="Post.Image" />
                <img src="img/img_2.png" alt="" v-if="!Post.Image">
              </div>
            </a>
          </li>
        </ul>
        <nav aria-label="Page navigation example">
          <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
          </paginate>
        </nav>
      </div>
    </div>
    <div class="wrapper list_1" v-if="CategoryData.Category.Type == 0">
      <div class="container">
        <div class="title_box">
          <h1>{{ CategoryData.Category.CategoryName }}</h1>
        </div>
        <ul class="list">
          <li v-for="Post in Posts" :key="Post.PId">
            <a href="javascript:void(0)" class="text" @click="gotoPost(Post)">
              <p class="time">{{ Post.PublicStartTime | timeString("YYYY/MM/DD") }}</p>
              <p>{{ Post.Title }}</p>
            </a>
          </li>
        </ul>
        <nav aria-label="Page navigation example">
          <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
          </paginate>
        </nav>
      </div>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Category",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CategoryData: null,
      Posts: null,
      SelectTag: '全部',
      local: "zh_tw",
      pageAll: 0,
      perPage: 6,
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Category",
        {
          params: { CId: to.params.CId, lang: $cookies.get("thinker_site_lang") },
        }
      )
      .then((response) => {
        this.CategoryData = response.data;
        if (this.CategoryData.Category.Type == 1) {
          this.perPage = 8;
        }
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Category",
        {
          params: { CId: this.$route.params.CId, lang: $cookies.get("thinker_site_lang") },
        }
      )
      .then((response) => {
        this.CategoryData = response.data;
        if (this.CategoryData.Category.Type == 1) {
          this.perPage = 8;
        }
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("thinker_site_lang")) {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = $cookies.get("thinker_site_lang");
    } else {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/PostList",
        type: "post",
        data: {
          CId: this.$route.params.CId,
          Tag: this.SelectTag,
          page: page,
          perPage: that.perPage,
          lang: $cookies.get("thinker_site_lang"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            this.QAIndex = -1;
            that.Posts = res.Posts;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId, CId: this.CategoryData.Category.CId },
        });
      }
    },
  },
};
</script>
