<template>
  <!-- 尾 -->
  <footer v-if="FooterData">
    <div class="line"></div>
    <div class="container" v-html="FooterData.Footer_Text">      
    </div>
    <div class="line_2">
      <div class="circle">
        <img src="img/map.svg" alt="">
      </div>
    </div>
  </footer>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "Footer",
  data() {
    return {
      FooterData: null,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Footer",
        {
          params: { lang: $cookies.get("thinker_site_lang") },
        }
      )
      .then((response) => {
        this.FooterData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
