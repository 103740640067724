<template>
  <div>
    <Head />

    <nav aria-label="breadcrumb" class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">聯絡我們</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper contact_form">
      <div class="bg">
        <div class="container">
          <div class="box">
            <div class="title_box">
              <h1>聯絡我們</h1>
            </div>
            <div class="text_box">
              <p>有任何問題或需求嗎？我們非常樂意聽取您的想法並提供專業建議，請留下您的聯絡資料以及具體需求，我們將盡快與您回覆。</p>
              <p>若您需要立即回覆，也歡迎您直接採用電話與我們聯繫。</p>
              <div class="img_box"></div>
            </div>
            <div class="form-group">
              <label>公司名稱</label>
              <input type="text" class="form-control" v-model="CompanyName" aria-describedby="" placeholder="請輸入公司名稱">
            </div>
            <div class="form-group">
              <label><span>*</span>您的姓名</label>
              <input type="text" class="form-control" v-model="Name" data-required="1" data-required-msg="請輸入姓名"
                aria-describedby="" placeholder="請輸入姓名">
            </div>
            <div class="form-group">
              <label><span>*</span>連絡電話</label>
              <input type="text" class="form-control" v-model="ContactNumber" data-required="1"
                data-required-msg="請輸入連絡電話" aria-describedby="" placeholder="請輸入連絡電話">
            </div>
            <div class="form-group">
              <label><span>*</span>您的信箱</label>
              <input type="email" class="form-control" v-model="Email" data-required="1" data-required-msg="請輸入信箱"
                aria-describedby="" placeholder="請輸入信箱">
            </div>
            <div class="form-group textarea_box">
              <label><span>*</span>詢問內容</label>
              <textarea class="form-control" v-model="Content" data-required="1" data-required-msg="請輸入詢問內容"
                aria-describedby="" placeholder="請輸入詢問內容">
              </textarea>
            </div>
            <div class="tick_box">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="PrivacyCheck" v-model="PrivacyCheck">
                <label class="form-check-label" for="PrivacyCheck">
                  我已閱讀並同意 <a href="/#/Post/297" title="隱私權與個資保護聲明" target="_blank">隱私權與個資保護聲明</a>
                </label>
              </div>
            </div>
            <div class="btn_box">
              <button>
                <p @click="send()">送出</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Contact",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CompanyName: '',
      Name: '',
      ContactNumber: '',
      Email: '',
      Content: '',
      local: "zh_tw",
      PrivacyCheck: false,
    };
  },
  created: function () {
  },
  mounted: function () {
    if ($cookies.isKey("thinker_site_lang")) {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = $cookies.get("thinker_site_lang");
    } else {
      i18n.locale = $cookies.get("thinker_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    send() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      var hasError = false;
      let that = this;
      $(".contact_form").find("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              //input.focus();
            },
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (!that.PrivacyCheck) {
        Swal.fire({
          title: "錯誤",
          text: "請勾選「我已閱讀並同意隱私權與個資保護聲明」",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        hasError = true;
        return false;
      }
      if (hasError) {
        return;
      }
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/Contact",
        type: "post",
        data: {
          CompanyName: that.CompanyName,
          Name: that.Name,
          ContactNumber: that.ContactNumber,
          Email: that.Email,
          Content: that.Content,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "送出成功",
              text: "我們已收到您的訊息, 將盡快與您聯絡!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    }
  },
};
</script>
